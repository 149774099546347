@import '~dd-cms-client/common/styles/globals';

.ColorInput {
  display: flex;
  position: relative;

  .MuiFormControl-root {
    flex-grow: 1;
  }
}

.ColorInput-Indicator {
  @include dds-box($dds-sizing-3);

  border-radius: $dds-border-radius-global;
  margin-left: -$dds-spacing-4;
  margin-top: $dds-spacing-3_5;
}

.ColorInput-Picker {
  position: absolute;
  top: 100%;
  z-index: 2;
}

.ColorInput-PickerCover {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
