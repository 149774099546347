$content-color: rgb(0 0 0 / 54%);

.Menu-ExpandableSectionHeader {
  align-content: center;
  cursor: pointer;
  display: flex;
  padding: 6px 16px;

  &:hover {
    background-color: rgb(0 0 0 / 4%);
    text-decoration: none;
  }

  & svg {
    color: $content-color;
  }
}

.Menu-ExpandableSectionIcon {
  margin-right: 16px;
}

.Menu-ExpandableSectionExpandIcon {
  color: $content-color;
}

.Menu-ExpandableSectionTitle {
  align-items: center;
  color: $content-color;
  display: flex;
  flex-grow: 1;
}

.Menu-ExpandableSectionContent {
  padding-left: 16px;
  padding-right: 16px;
}

.Menu-ExpandableSectionContent--SidebarClosed {
  padding-left: 0;
  padding-right: 0;
}
