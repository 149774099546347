@import '~dd-cms-client/common/styles/globals';

.Layout--Daydeal {
  .RaAppBar-toolbar {
    background-color: $dds-color-daydeal;
  }
}

.Layout--Partnerdeal {
  .RaAppBar-toolbar {
    background-color: $dds-color-partnerdeal;
  }
}

.layout { /* stylelint-disable-line selector-class-pattern */
  .RaAppBar-toolbar {
    [class*='RaLoadingIndicator'] {
      display: none;
    }
  }
}
