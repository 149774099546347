@import '~dd-cms-client/common/styles/globals';

.Dialog {
  .MuiPaper-root {
    max-height: 100%;
    max-width: 100%;
    width: 700px;
  }

  .MuiDialogContent-root {
    justify-content: center;
  }

  .MuiDialogActions-root {
    justify-content: center;
  }

  .MuiCardContent-root {
    padding-top: 0;
  }

  .Dialog-ContentActions {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      &:not(:first-child) {
        margin-left: $dds-spacing-2;
      }
    }
  }

  /* stylelint-disable selector-class-pattern */
  .create-page,
  .edit-page {
    .MuiPaper-root {
      box-shadow: none;
    }
  }
}
