@import '~dd-cms-client/common/styles/globals';

.LanguageList-Row {
  align-items: center;
  background-color: $dds-color-gray-100;
  border-bottom: 1px solid $dds-color-gray-300;
  display: flex;
  justify-content: space-between;
  padding: $dds-spacing-4 $dds-spacing-1_5;
}
