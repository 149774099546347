@import '~dd-cms-client/common/styles/globals';

.StickerList-IconCell {
  width: 5%;
}

.StickerList-NameLabelCell,
.StickerList-ImageCell,{
  width: 15%;
}

.StickerList-Name,
.StickerList-Label {
  margin: $dds-spacing-0_5 0;
}

.StickerList-UrlCell {
  width: 40%;
}

.StickerList-ActionsCell {
  width: 10%;
}
