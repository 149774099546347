@import '~dd-cms-client/common/styles/globals';

.LinkAddForm-FormContainer {
  align-items: center;
  display: flex;
  margin-bottom: $dds-spacing-4;
  width: 100%;
}

.LinkAddForm-Form {
  display: flex;
  flex-direction: column;
  margin-right: $dds-spacing-4;
  width: 380px;
}

.LinkAddForm-AddButtonWrapper {
  margin-top: $dds-spacing-1;
}

.LinkAddForm-AddButton {
  width: 100%;
}

.LinkAddForm-FileInput {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.FileInput-PreviewItem {
  @include dds-type-scale(-2);
}
