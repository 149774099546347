@import '~dd-cms-client/common/styles/globals';

.MediaTab-InputsWrapper {
  display: flex;
}

.MediaTab-ImageInput {
  display: flex;
  flex-direction: column;
}

.MediaTab-VideoInput {
  display: flex;
  flex-direction: column;
  margin-left: $dds-spacing-4;
  margin-top: $dds-spacing-1_5;

  & > div {
    width: 380px;
  }

  button {
    align-self: flex-start;
  }
}
