@import '~dd-cms-client/common/styles/globals';

.LoginPage-Form {
  @include dds-pos(absolute, $top: 50%, $left: 50%);

  align-items: center;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  width: 100%;
}

.LoginPage-Header {
  color: $dds-color-gray-600;
  font-family: $dds-font-family-secondary;
  margin-bottom: $dds-spacing-1;
  margin-top: 0;
}

.LoginPage-Input,
.LoginPage-SubmitButton {
  width: $dds-sizing-35;

  input {
    font-family: $dds-font-family-secondary;
  }
}

.LoginPage-SubmitButton {
  @include dds-type-scale(-1);

  background-color: $dds-color-daydeal;
  border: 1px solid $dds-border-color;
  border-radius: $dds-border-radius-global;
  color: $dds-color-white;
  cursor: pointer;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  margin-top: $dds-spacing-1;
  padding: $dds-spacing-1;
  text-transform: uppercase;

  &:hover {
    opacity: .9;
  }
}

.LoginPage-SubmitButton--Loading {
  background-color: $dds-color-gray-300;
  cursor: not-allowed;
}
