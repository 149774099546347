@import '~dd-cms-client/common/styles/globals';

.Menu {
  height: calc(100% - #{$dds-spacing-5});

  .Menu-Link {
    text-transform: capitalize;
  }

  .Menu-Link--Gap {
    border-top: 1px solid $dds-border-color;
    margin-top: auto;
  }

  .Menu-UserSettings-Container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
