@import '~dd-cms-client/common/styles/globals';

.RichTextEditor {
  margin-bottom: $dds-spacing-2_5;
}

.RichTextEditor-Label {
  @include dds-type-scale(-2);

  color: rgb(0 0 0 / 60%);
}

.RichTextEditor-Error {
  &,
  .RichTextEditor-Label {
    color: $dds-color-error;
  }
}

.RichTextEditor-ErrorMessage {
  @include dds-type-scale(-2);
}
