@import '~dd-cms-client/common/styles/globals';

.ContextMenu {
  .MuiTooltip-tooltip {
    background-color: $dds-color-white;
    border-radius: $dds-border-radius-global * 2;
    box-shadow:  0 $dds-spacing-0_5 $dds-spacing-2 0 rgb(0 0 0 / 15%);
    color: $dds-color-black;
    /* stylelint-disable-next-line declaration-no-important */
    margin: $dds-spacing-0_5 0 0 !important; // it has to be add to override MUI tooltip
    padding: $dds-spacing-1 $dds-spacing-2;
  }
}

.ContextMenu-TriggerButton {
  @at-root button#{&} {
    @include dds-box($dds-sizing-4);

    min-width: $dds-sizing-4;
    padding: 0;
  }
}
