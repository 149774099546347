@import '~dd-cms-client/common/styles/globals';

.ShowOnPage-RadioGroup--Disabled {
  opacity: 0.6;
  pointer-events: none;
}

.ShowOnPage-Select {
  margin-left: $dds-spacing-4;
  margin-top: -$dds-spacing-4;
}
