@import '~dd-cms-client/common/styles/globals';

.LinksList-Loader {
  padding: $dds-spacing-5;
  text-align: center;
}

.LinksList-TitleCell,
.LinksList-IconCell,
.LinksList-ActionCell {
  width: 10%;
}

.LinksList-UrlCell {
  width: 60%;
}

.LinksList-UrlLink {
  color: $dds-color-blue;
}
