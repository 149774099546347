@import '~dd-cms-client/common/styles/globals';

.SkuInput {
  align-items: center;
  display: flex;
}

.SkuInput-Form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.SkuInput-Loader {
  margin-left: $dds-spacing-2;
}
