@import '~dd-cms-client/common/styles/globals';

.VariantTable-VariantRow {
  background-color: $dds-color-white;
}

.VariantTable-IconCell {
  width: 5%;
}

.VariantTable-ImageCell,
.VariantTable-IdCell,
.VariantTable-AvailableStock,
.VariantTable-SpecialPrice,
.VariantTable-OriginalPrice,
.VariantTable-Status {
  width: 10%;
}

.VariantTable-NameCell {
  width: 25%;
}

.VariantTable-Actions {
  width: 30%;
}
