@import '~dd-cms-client/common/styles/globals';

.Comment-DealField {
  @include dds-type-scale(-1);

  align-items: center;
  display: flex;
  line-height: $dds-line-height-list;
}

.Comment-DealFieldImage {
  border: 1px solid $dds-color-gray-200;
  border-radius: $dds-border-radius-global;
  margin-right: $dds-spacing-1;
  max-width: $dds-sizing-7;
}

.Comment-DealFieldTitleLanguageWrapper {
  display: flex;

  .ActiveLanguages {
    margin-right: $dds-spacing-1;
  }
}
