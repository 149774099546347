@import '~dd-cms-client/common/styles/globals';
@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-cms-client/common/styles/vendor/bootstrap-custom-variables';

.Comment-ActionsField {
  align-items: center;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-up(md){
    align-items: stretch;
  }
}

.Comment-ActionsFieldButtonsWrapper {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    margin-right: $dds-spacing-1;
  }
}

.Comment-ActionsFieldButton {
  &.MuiButton-root  {
    justify-content: flex-start;
    margin-right: $dds-spacing-3;
    white-space: nowrap;

    @include media-breakpoint-up(md){
      margin-right: auto;
      padding: $dds-spacing-0_5;
    }
  }
}

.Comment-ActionsFieldContextMenu {
  margin-left: $dds-spacing-1;
}

.Comment-ActionsFieldDialog {
  .MuiTextField-root { /* stylelint-disable-line selector-class-pattern */
    max-width: none;
  }

  .ra-input-id { /* stylelint-disable-line selector-class-pattern */
    display: none;
  }
}
