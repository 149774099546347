@import '~dd-cms-client/common/styles/globals';

.CommentThread-ReplyWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: $dds-spacing-4 0;
}

.CommentThread-ReplyFormTitle {
  &.Title {
    margin-bottom: $dds-spacing-1;
  }
}

.CommentThread-ButtonPublishWrapper {
  align-items: center;
  display: flex;
}

.CommentThread-PostAuthor {
  color: $dds-color-gray-500;
  margin-left: $dds-spacing-2;
}

.CommentThread-ReplyBox {
  background-color: lighten($dds-color-orange, 30);
  border-radius: $dds-border-radius-global;
  padding: $dds-spacing-1_5 $dds-spacing-2;
}

.CommentThread-ReplyBoxAuthor {
  color: $dds-color-gray-500;
  display: block;
  margin-top: $dds-spacing-0_5;
}

.CommentThread-ReplyForm {
  &.MuiCardContent-root {
    padding: 0;
  }
}

.CommentThread-TextEditor {
  margin-top: $dds-spacing-2;
  max-width: 100%;
  width: $dds-spacing-25 * 3;
}

