@import '~dd-cms-client/common/styles/globals';

.VariantList-GroupNameRow {
  background-color: $dds-color-gray-100;
}

.VariantList-Image {
  max-width: $dds-sizing-10;
}

.VariantList-CheckboxGroupOrderWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  margin-top: -$dds-spacing-5;
}

.VariantList-CheckboxGroupOrder {
  &.MuiFormControlLabel-root {
    margin: 0;
  }
}

.VariantList-Loader {
  padding: $dds-spacing-5;
  text-align: center;
}
