@import '~dd-cms-client/common/styles/globals';

.ListActions {
  @at-root .List & {
    align-items: center;
  }

  align-self: flex-start;
  flex: 0 0 $dds-sizing-35;
}

.ListActions--Narrow {
  flex-basis: $dds-sizing-20;
}
