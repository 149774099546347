@import '~dd-cms-client/common/styles/globals';

.UrlField {
  display: flex;
  margin-bottom: $dds-spacing-3;
}

.UrlField-Label {
  margin-right: $dds-spacing-0_5;
}
