@import '~dd-cms-client/common/styles/globals';

.StickersList-DescWrapper,
.StickersList-URLsWrapper {
  display: flex;
  flex-direction: column;
}

.StickersList-DescTextLanguage {
  margin: $dds-spacing-0_5 0;
}

.StickersList-URL {
  margin-bottom: $dds-spacing-0_5;
}
