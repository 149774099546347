@import '~dd-cms-client/common/styles/globals';

.ManifestFieldGroup-BaseColors,
.ManifestFieldGroup-DesignTokens,
.ManifestFieldGroup-DesignTokensFields {
  margin-bottom: $dds-spacing-4;
}

.ManifestFieldGroup-DesignTokensFields {
  display: none;
}

.ManifestFieldGroup-DesignTokensFields--Displayed {
  display: block;
}
