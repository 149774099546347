@import '~dd-cms-client/common/styles/globals';

.CommentListMobile-Wrapper {
  border-bottom: 1px solid $dds-color-gray-200;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: $dds-spacing-2;
  width: 100%;

  &:first-child {
    border-top: 1px solid $dds-color-gray-200;
  }
}

.CommentListMobile-Icon {
  margin: 0;
}

.CommentListMobile-Content {
  margin: $dds-spacing-1 0;
}

.CommentListMobile-Details {
  align-items: center;
  color: $dds-color-gray-500;
  display: flex;
  flex-wrap: wrap;
  font-size: $dds-font-size-small;
  margin-bottom: $dds-spacing-2;
}

.CommentListMobile-Status {
  @include dds-type-scale(-2)
}

.CommentListMobile-DetailsAuthor,
.CommentListMobile-DetailsDate {
  margin-right: $dds-spacing-2;
}

.CommentLisMobile-Deal{
  margin-bottom: $dds-spacing-2;
}
