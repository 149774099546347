@import '~dd-cms-client/common/styles/globals';

.List {
  form {
    margin-bottom: $dds-spacing-1;
    margin-top: 0;
    min-height: auto;
  }
}

.List-Chip {
  border-radius: $dds-border-radius-global;
  color: $dds-color-white;
  padding: $dds-spacing-0_25 $dds-spacing-0_75;
}

.List-Chip--Status {
  background-color: $dds-color-primary;
}

.List-Chip--StatusInactive {
  background-color: $dds-color-red;
}

.List-Chip--StatusPending {
  background-color: $dds-color-orange;
}

.List-Chip--Category {
  background-color: $dds-color-light-green;
}

.List-Image {
  max-height: $dds-sizing-13;
  max-width: $dds-sizing-15;
}

.List-Title {
  @include dds-type-scale(0);

  font-weight: $dds-font-weight-bold;
}

.List-Select {
  .MuiFormHelperText-root {
    font-size: 0;
  }
}

.List-CellContent {
  display: inline-block;
}

.List-CellContent--MarginTop {
  margin-top: $dds-spacing-0_5;
}

.Chip--MaterialUi {
  &.MuiChip-root {
    cursor: inherit;
    margin: 4px;
  }
}

.TopToolbar {
  align-self: flex-start;
  flex: 0 0 $dds-sizing-35;
}

.Title {
  @include dds-type-scale(1);

  font-weight: $dds-font-weight-bold;
  margin-bottom: $dds-spacing-2;
}

.Field--Disabled {
  pointer-events: none;

  .MuiInputBase-root {
    background-color: rgb(0 0 0 / 12%);
    color: rgb(0 0 0 / 38%);
  }
}
