@import '~dd-cms-client/common/styles/globals';

.Comment-TitleWrapper {
  align-items: center;
  display: flex;
  margin-bottom: $dds-spacing-0_5;
}

.Comment-ReplyIcon {
  &.MuiSvgIcon-root {
    @include dds-box($dds-sizing-2);

    color: $dds-color-gray-500;
    margin-right: $dds-spacing-0_5;
  }
}

.Comment-ReplyLabel {
  color: $dds-color-gray-500;
  margin-right: $dds-spacing-1;
}

.Comment-Title {
  color: $dds-color-blue;
  font-weight: $dds-font-weight-bold;
}

.Comment-ArrowRightIcon {
  &.MuiSvgIcon-root {
    @include dds-box($dds-sizing-2_5);

    color: $dds-color-blue;
  }
}

.Comment-Message {
  margin-bottom: $dds-spacing-0_5;
}

.Comment-AuthorName {
  color: $dds-color-gray-500;
}
