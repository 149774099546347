@import '~dd-cms-client/common/styles/globals';

.LinkEditDialog-Container {
  column-gap: $dds-spacing-1_5;
  display: flex;
}

.LinkEditDialog-Form {
  display: flex;
  flex-direction: column;
  padding: $dds-spacing-1_5 0;
  width: 380px;
}

.LinkEditDialog-FormButtonsContainer {
  column-gap: $dds-spacing-1_5;
  display: flex;
  margin-top: $dds-spacing-1_5;
}
