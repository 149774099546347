@import '~dd-cms-client/common/styles/globals';

.SelectDeal-Option {
  align-items: center;
  display: flex;
}

.SelectDeal-OptionImage {
  margin-right: $dds-spacing-1;
  max-width: $dds-sizing-7;
}
