@import '~dd-cms-client/common/styles/globals';

.CommentThread-CommentFeedLink {
  @include dds-link($dds-color-blue);

  display: inline-block;
  margin-bottom: $dds-spacing-1;
}

.CommentThread-DealField {
  border-bottom: 1px solid $dds-color-gray-200;
  padding-bottom: $dds-spacing-4;
}

.CommentThread-StateTitleWrapper {
  display: flex;
}

.CommentThread-TitleAndMessage {
  .Comment-Title {
    color: $dds-color-black;
  }

  .Comment-ArrowRightIcon {
    display: none;
  }
}

.CommentThread-StateIcon {
  &.StateIcon {
    margin-right: $dds-spacing-3;
  }
}

.CommentThread-ReplyStateCell {
  &.MuiTableCell-root {
    padding: 0;
    width: 0;
  }
}

.CommentThread-ReplyRow {
  td {
    &:first-child {
      border-bottom: 0;
    }
  }
}
