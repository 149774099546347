@import '~dd-cms-client/common/styles/globals';

.FileInput {
  > div {
    &:first-of-type {
      border: 2px dashed $dds-color-gray-300;
      padding: $dds-spacing-1 $dds-spacing-2;

      @at-root .ImageInput--DragOver#{&} {
        border: 2px dashed $dds-color-light-green;
      }
    }
  }

  // It is required to hide button in RaFileInput component
  .previews { /* stylelint-disable-line selector-class-pattern */
    display: none;
  }
}

.FileInput-Preview {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.FileInput-PreviewItem {
  margin-bottom: $dds-spacing-2;
  margin-right: $dds-spacing-2;
}

.FileInput-PreviewImage {
  max-width: $dds-sizing-30;
}

.FileInput-PreviewRemoveLink {
  display: block;
  text-align: center;
}

.FileInput-Loader {
  align-self: center;
  padding: $dds-spacing-6 0;
  text-align: center;
  width: $dds-sizing-13;
}

.FileInput--Error {
  > p {
    color: $dds-color-error;
  }
}

.FileInput--Disable {
  opacity: 0.6;
  pointer-events: none;
}

.FileInput-Preview--Disable {
  opacity: 0.6;
  pointer-events: none;

  .FileInput-Loader {
    display: none;
  }
}
