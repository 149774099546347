@import '~dd-cms-client/common/styles/globals';

.RecommendationList-IconCell {
  width: 10%;
}

.RecommendationList-ImgCell {
  width: 20%;
}

.RecommendationList-Image {
  max-width: $dds-sizing-13;
}

.RecommendationList-TitleCell {
  width: 60%;
}

.RecommendationList-SkuCell {
  width: 10%;
}

.RecommendationList-ActionCell {
  width: 10%;
}
