@import '~dd-cms-client/common/styles/globals';

.MediaList-Image {
  max-width: $dds-sizing-13;
}

.MediaList-IconCell {
  width: 10%;
}

.MediaList-ImgCell {
  width: 20%;
}

.MediaList-InputCell {
  width: 60%;
}

.MediaList-TypeCell {
  width: 10%;
}

.MediaList-ActionCell {
  width: 10%;
}
