@import '~dd-cms-client/common/styles/globals';

.SchemaFields {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: $dds-spacing-25 * 4;

  .ColorInput,
  .MuiTextField-root {
    max-width: 480px;
    width: 100%;
  }

  .TextInput--Multiline {
    textarea {
      min-height: $dds-sizing-6;
    }
  }
}

.RaArrayInput-root {
  .RaSimpleFormIterator-form {
    flex-grow: 1;
  }
}

.RaSimpleFormIterator-action {
  padding-top: $dds-spacing-1_5;
}

.RaSimpleFormIterator-buttons {
  border-bottom: solid 1px rgb(0 0 0 / 12%);
  margin-bottom: $dds-spacing-3;
}
