@import '~dd-cms-client/common/styles/globals';

body { // specially body added to enforce higher importance
  .confirm-box { /* stylelint-disable-line selector-class-pattern */
    left: 50%;
    position: fixed;
    top: 50%;
  }

  .confirm-box__overlay { /* stylelint-disable-line selector-class-pattern */
    background-color: rgb(0 0 0 / 50%);
  }
}

.ConfirmDialog {
  transform: translate(-50%, -50%);

  .MuiDialogActions-root {
    justify-content: center;
  }
}
