/* ==========================================================================
   Custom Mixins
   ========================================================================== */

/**
 * dds-type-scale
 * Sets proper font-size & line-height based on global typography settings
 * Line-height (unitless) is calculated based on font-size to fit into vertical grid
 * Use margin-bottom property to include additional line below
 * Use line-height property to adjust leading (line-height)
 *
 * Examples:
 * @include dds-type-scale(1), @include dds-type-scale(h1,1);
 */

@use 'sass:math';

@mixin dds-type-scale($scale, $margin-bottom: 0, $line-height: $dds-line-height-base) {
  // returns number of half-lines given font-size occupies
  $line-count: ceil(math.div(map-get($dds-type-scales, $scale), ($line-height * 0.5)));
  $current-font-size: map-get($dds-type-scales, $scale);
  $current-line-height: math.div($line-count * ($line-height * 0.5), $current-font-size);

  font-size: rem($current-font-size);
  line-height: $current-line-height + 0.00001;

  @if $margin-bottom != 0 {
    margin: 0 0 rem($margin-bottom * $dds-line-height-base);
  } @else {
    margin: 0;
  }
}

/**
 * dds-font-size
 * Retrieves proper font-size from font-sizing map
 *
 * Examples:
 * @include dds-font-size(small);
 */

@mixin dds-font-size($scale) {
  $current-font-size: map-get($dds-type-scales, $scale);

  font-size: rem($current-font-size);
}

/**
 * dds-BOX
 * Sets both height and width of element
 */

@mixin dds-box($width, $height: $width) {
  height: $height;
  width: $width;
}

/**
 * dds-FORM-PLACEHOLDER
 */

@mixin dds-input-placeholder($color: var(--dds-input-placeholder-color), $opacity: null) {
  /* stylelint-disable selector-no-vendor-prefix */
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::placeholder {
    color: $color;
    opacity: $opacity;
  }
  /* stylelint-enable selector-no-vendor-prefix */
}

/**
 * dds-POS
 * Shortcut for positioning properties
 */

@mixin dds-pos($position, $top: null, $right: null, $bottom: null, $left: null) {
  bottom: $bottom;
  left: $left;
  position: $position;
  right: $right;
  top: $top;
}

/**
 * dds-BUTTON
 * Sets background color and border for different button states
 */

@mixin dds-button(
  $bg: null,
  $bg-hover: $bg,
  $bg-active: $bg,
  $bg-focus: null,
  $border: null,
  $color: null,
  $color-hover: null,
  $color-active: null,
) {
  background-color: $bg;
  color: $color;

  @if $border {
    box-shadow: inset 0 0 0 1px $border;
  }

  &:hover {
    background-color: $bg-hover;
    color: $color-hover;
  }

  &:active {
    background-color: $bg-active;
    color: $color-active;
  }

  &:focus {
    background-color: $bg-focus;
  }
}

/**
 * dds-INPUT-BORDER
 * default input border
 */

@mixin dds-input-border($border-color: var(--dds-input-border-color)) {
  border: 1px solid $border-color;
}

/**
 * dds-UL-UNSTYLED
 * Removes default styles on ul, ol elements
 * Use instead of writing these rules manually
 */

@mixin dds-list-unstyled($isInline: true) {
  list-style: none;
  margin: 0;
  padding: 0;

  @if $isInline {
    & > li {
      display: inline-block;
    }
  }
}

/**
 * dds-LINK
 * Creates styles for all link states
 */

@mixin dds-link($link-color: var(--dds-gray-900), $hover: $link-color, $visited: $link-color) {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $hover;
    text-decoration: underline;
  }

  &:visited {
    color: $visited;
  }

  &:visited:hover {
    color: $hover;
  }
}
