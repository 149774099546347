@import '~dd-cms-client/common/styles/globals';

.ContextMenu-List {
  @include dds-list-unstyled(false);
}

.ContextMenu-ListButton {
  @include dds-type-scale(-1);

  background-color: $dds-color-white;
  border: 0;
  cursor: pointer;
  font-weight: $dds-font-weight-semibold;
  padding: $dds-spacing-0_25 0;

  &:disabled {
    cursor: default;
  }
}
