@import '~dd-cms-client/common/styles/globals';
@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-cms-client/common/styles/vendor/bootstrap-custom-variables';

.List--ActionHidden {
  .MuiToolbar-root {
    display: none;
  }
}

.CommentList-PostNewWrapper {
  display: flex;
}

.CommentList-PostNewButton {
  &.MuiButton-root {
    margin-bottom: $dds-spacing-2;
    margin-left: auto;
    margin-top: $dds-spacing-2;
  }
}

.CommentList-FilterSearch {
  border-top: 1px solid $dds-color-gray-200;
  display: flex;
  font-weight: $dds-font-weight-bold;
  justify-content: space-between;
  padding: $dds-spacing-2 0;
}

.CommentList-Wrapper {
  form {
    .filter-field { /* stylelint-disable-line selector-class-pattern */
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }

      .RaFilterFormInput-spacer {
        display: none;

        @include media-breakpoint-up(md) {
          display: block;
        }
      }
    }
  }
}

.CommentList-Dialog {
  .MuiTextField-root {
    max-width: none;
  }
}

.CommentList-FilterField {
  width: 100%;

  @include media-breakpoint-up(md){
    width: auto;
  }
}

.CommentList-SelectDeal {
  @include media-breakpoint-up(md){
    max-width: 100%;
    width: $dds-sizing-25 * 2;
  }
}

.CommentList-Datagrid {
  th {
    &:first-child {
      padding: 0;
      width: 0;
    }
  }

  tbody {
    td {
      &:first-child {
        padding: 0;
        width: 0;
      }
    };
  }
}

.CommentList-ActionsCell {
  cursor: default;
}
