@import '~dd-cms-client/common/styles/globals';

.StickersTab-Container {
  .MuiBox-root {
    padding: 0;
  }

  .MuiCardContent-root {
    padding-top: $dds-spacing-1;
  }
}

.StickersTab-AddCreateButtonsWrapper {
  margin-bottom: $dds-spacing-2;
}

.StickersTab-ActiveLanguageSpan {
  font-weight: $dds-font-weight-bold;
  text-transform: uppercase;
}
