@import '~dd-cms-client/common/styles/globals';

.Password {
  align-items: flex-start;
  display: flex;

  .Password-Button {
    margin-left: $dds-spacing-1;
    margin-top: $dds-spacing-2;
  }
}
