@import '~dd-cms-client/common/styles/globals';

.AccessoriesTable-AddGroupContainer {
  align-items: center;
  column-gap: $dds-spacing-1;
  display: flex;
  justify-content: space-between;
  margin-bottom: $dds-spacing-2;
}

.AccessoriesTable-AddSkuContainer {
  .AccessoriesTable-AddSkuFormCell {
    align-items: center;
    column-gap: $dds-spacing-1;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}

.AccessoriesTable-AddSkuLabelCell {
  width: 5%;
}

.AccessoriesTable-GroupName {
  background-color: $dds-color-gray-100;
}

.AccessoriesTable-ButtonsGroupContainer {
  column-gap: $dds-spacing-1;
  display: flex;
}

.AccessoriesTable-AddSkuButton {
  height: $dds-sizing-4;
}
