@import '~dd-cms-client/common/styles/globals';

.AccessoriesImported-Container {
  margin-top: $dds-spacing-10;
}

.AccessoriesImported-Header {
  @include dds-type-scale(3);

  font-weight: $dds-font-weight-bold;
  text-transform: uppercase;
}

.AccessoriesImported-GroupName {
  background-color: $dds-color-gray-100;
}
