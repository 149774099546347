@import '~dd-cms-client/common/styles/globals';
@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-cms-client/common/styles/vendor/bootstrap-custom-variables';

.AppBar-LanguageSelector {
  margin-left: auto;

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}

.RaAppBar-appBar {
  font-size: $dds-font-size-small;
  line-height: $dds-line-height-list;

  .RaLoadingIndicator-loadedIcon { // hide refresh button
    display: none;
  }

  .RaLoadingIndicator-loader { // hide loader
    display: none;
  }
}

.AppBar-Title {
  margin-right: auto;
}

.AppBar-TenantSlash {
  margin-left: $dds-spacing-1;
  margin-right: $dds-spacing-2;
}

.AppBar-CommentsLink {
  color: $dds-color-white;
  margin-right: $dds-spacing-3;
  text-decoration: none;

  .List-Chip {
    background-color: $dds-color-white;
    color: $dds-color-primary;
    margin-left: $dds-spacing-1;
  }
}
