@import '~dd-cms-client/common/styles/globals';

.List {
  width: 100%;

  .RaList-main {
    width: 100%;

    .RaList-content{
      box-shadow: none;
    }
  }
}

.StickersGlobalList-ButtonsActionWrapper {
  margin-top: $dds-spacing-2;
}

.StickersGlobalList-StickerItemContainer {
  align-items: center;
  display: flex;
  margin: $dds-spacing-2 0;
}

.StickersGlobalList-ItemDescContainer {
  align-items: center;
  display: flex;
  margin-left: $dds-spacing-2;
  width: 100%;
}

.StickersGlobalList-ItemDesc {
  margin: $dds-spacing-0_5 0;
}
