@import '~dd-cms-client/common/styles/globals';

.AppsList-AppDescriptionContainer {
  align-items: center;
  display: flex;
  margin-bottom: $dds_spacing-2;
  width: 500px;
}

.AppsList-AppDescriptionInput {
  width: 70%;
}

.AppsList-ImageCell {
  width: 10%;
}

.AppsList-UrlInput {
  width: 50%;
}
