@import '~dd-cms-client/common/styles/globals';

.GroupTable {
  margin-top: $dds-spacing-3;
}

.GroupTable-IconCell {
  width: 5%;
}

.GroupTable-GroupNameCell {
  width: 100%;
}
