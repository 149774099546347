@import '~dd-cms-client/common/styles/globals';

.Body--FormToolbar {
  margin-bottom: 4em;

  .MuiDrawer-root {
    &,
    & .MuiPaper-root > * {
      height: calc(100% - 7em);
    }
  }
}

.FormToolbar {
  border-top: 1px solid $dds-color-gray-300;
  bottom: 0;
  box-sizing: border-box;
  column-gap: 1em;
  justify-content: center;
  left: 0;
  width: 100%;
  z-index: 1500;

  &.FormToolbar {
    position: fixed;
  }
}
