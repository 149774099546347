@import '~dd-cms-client/common/styles/globals';

.LangChip {
  @include dds-type-scale(-1);

  background-color: $dds-color-gray-300;
  border-radius: $dds-border-radius-global;
  display: inline-block;
  font-weight: $dds-font-weight-semibold;
  line-height: normal;
  padding: $dds-spacing-0_25;
}

.LangChip--Tab {
  background-color: transparent;
  border-radius: unset;
  padding: 0;
}
